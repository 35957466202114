import FacebookIcon from '../assets/Facebook.svg';
import TwitterIcon from '../assets/Twitter.svg';
import LinkedinIcon from '../assets/LinkedIn.svg';

export const socialMediaIcons = [
  {
    id: 1,
    src: FacebookIcon,
    alt: 'Facebook',
    url: 'https://facebook.com/RoofScope',
  },
  {
    id: 2,
    src: TwitterIcon,
    alt: 'Twitter',
    url: 'https://twitter.com/roofscope',
  },
  {
    id: 3,
    src: LinkedinIcon,
    alt: 'LinkedIn',
    url: 'https://www.linkedin.com/company/scope-tech',
  }
];
  
  export const navigationLinks = [
    { text: 'Contact Us', url: 'https://roofscope.com/contact' },
    { text: 'FAQs', url: 'https://roofscope.com/page/faq' },
    { text: 'Terms of Use', url: 'https://roofscope.com/terms' },
    { text: 'Privacy Policy', url: 'https://roofscope.com/privacy' },
    { text: 'Partners', url: 'https://roofscope.com/partners' },
  ];